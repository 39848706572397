<template>
  <div class="dialog">
    <div class="dialog-content">
      <img class="close-btn" @click="cloneClick" src="../assets/img/rulesClose.png" alt="">
      <h3>{{title}}</h3>
      <div class="detail" v-html="text"></div>
      <div style="height: 100px"></div>
      <div class="agree" @click="tongyi">同意并继续<span class="time">{{ countdown == 0?'':' ( '+countdown+' ) ' }}</span></div>
    </div>+
  </div>
</template>

<script>
import { zfbPost} from '../assets/js/Common';
import axios from "axios";
import { Toast } from "vant";
export default {
  name: 'countdown',
  data() {
    return {
      mess: this.$guize,
      countdownSeconds: 3,
      timerId: null,
      countdown: 0,
      show:false,
      text:'',
      title:'',
    }
  },
  props: {
    msg: Object
  },
  created() {
    console.log(this.msg);
    if(this.msg.mess == 0){
      this.text = this.mess.yinsi4
      this.title = '连续包月协议'
    }else{
      this.text = this.mess.yinsi5
      this.title = '会员协议'
    }
    this.startCountdown()
  },
  methods: {
    tongyi(){
      if(this.show){
        let data = {}
        if (this.msg.mess == 0) {
            data.bizcode = 'wechat_sign_request',
            data.productId ='WECHAT_ZXG_MWEB1990'
        }else{
            data.bizcode = 'wechat_unified_request',
            data.productId ='wechatUnified2500'
        }
        this.clickcode(data)
      }
    },
    cloneClick() {
      this.$emit('custom-event')
      this.cancelCountdown()
    },
    startCountdown() {
      let duration = this.countdownSeconds;
      this.countdown = duration;
      this.timerId = setInterval(() => {
        duration--;
        this.countdown = duration;
        if (duration <= 0) {
          clearInterval(this.timerId);
          // zfbPost(this.msg.phone, this.msg.pollid, this.msg.pollid.whether)
          this.show = true
        }else{
          this.show = false
        }
      }, 1000);
    },
    cancelCountdown() {
      clearInterval(this.timerId);
      this.timerId = null;
      this.countdown = 0;
    },
    clickcode(item) {
      this.$emit('custom-eventC')
      let that = this;
      var appkey = this.$common.AllgetQueryId("appkey") || "woread_equity";
      let othersVal = window.location.href;
      var a_oId = this.$common.AllgetQueryId("a_oId");
      let ip = sessionStorage.getItem("userIp");
      this.orderNo = this.$common.Allrandom_No(5);
      this.pollId = this.$common.AllgetQueryId("pollId");
      let data = {
        appkey: appkey,
        bizcode: item.bizcode,
        orderNo: this.orderNo,
        phone: this.msg.phone,
        sign: "byt",
        ip: ip,
        imei: "",
        imsi: "",
        others: othersVal,
        productId: item.productId,
      };
      this.$loading.show();
      axios
        .post(
          "https://activity.aizhishifm.com/clb/jifei/rest/open/exec",
          data,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (res) {
          that.$loading.hide();
          if (res.data.flag == true) {
            if (a_oId) {
              // 推啊
              countLog.ready(function () {
                countLog.init(function () {}, {
                  // 是否需要复制订单号，是为 1，不是为 0
                  isCopy: 0,
                  // 上报转化类型，具体见下表
                  pageType: 0,
                });
              });
            }
            let currentUrl = "https://www.bytxxz.com/qmj/vip.html";
            window.location.href =
              "http://wxpay.bytxxz.com/?redirectUrl=" +
              encodeURIComponent(res.data.data.redirect_url) +
              "&backUrl=" +
              encodeURIComponent(currentUrl);
            setTimeout(() => {
              window.location.href = 'https://www.bytxxz.com/qmj/vip.html'
              // window.location.href = "https://m.woread.diamond.aizhishifm.cn/benefit.html";
            }, 5000);
          } else {
            that.$common.liuliangguo(that.tel, 100875, res.data.msg);
            Toast(res.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  }
}
</script>

<style scoped lang="scss">
.time {
  color: #3b3b3b98;
  padding-left: 5px;
}
.detail{
  height: 76%;
}
.agree{
  font-weight: 700;
}
</style>
