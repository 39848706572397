<template>
  <div class="dialog">
    <div class="dialog-content" v-if="showpay">
      <img
        class="close-btn"
        @click="cloneClick"
        src="../assets/img/rulesClose.png"
        alt=""
      />
      <h3>付款授权服务协议</h3>
      <div class="detail" v-html="this.mess.empower"></div>
      <div class="agree" @click="tongyi">
        同意并继续
      </div>
    </div>
    <div v-else class="illustrate">
      <div class="illustrate-content">
        <div class="illustrate-content-top" style="margin-top: 15px">
          <span style="font-size: 18px; font-weight: 600">订阅规则说明：</span>
          <img
          @click="cloneClick"
            class="cloneClick"
            style="width: 20px"
            src="../assets/img/rulesClose.png"
            alt=""
          />
        </div>
        <div class="illustrate-content-div">
          <span class="timeA">{{ timeC }}</span
          >:
          <span style="color: #f2a72f">19.9元开通联合会员特权</span>
        </div>
        <img
          style="width: 25px; margin: auto"
          src="../assets/img/xiajt.png"
          alt=""
        />
        <div class="illustrate-content-div">续费前提醒</div>
        <img
          style="width: 25px; margin: auto"
          src="../assets/img/xiajt.png"
          alt=""
        />
        <div class="illustrate-content-div">
          <span class="timeB">{{ timeD }}</span
          >: 会员到期，进行下一次续费
        </div>
        <div class="illustrate-content-zfb">
          <span>支付方式</span>
          <span style="vertical-align: auto"
            ><img
              style="
                width: 25px;
                display: inline-block;
                margin-right: 4px;
                vertical-align: sub;
              "
              src="../assets/img/zfblogo.png"
              alt=""
            /><span>支付宝</span>
          </span>
        </div>
        <div class="illustrate-content-button" @click="zhifu" :style="{ backgroundColor: show ? 'rgba(0, 8, 247, 0.77)' : 'rgb(179, 175, 172)' }">
          请阅读订阅规则<span class="time">{{
          countdown == 0 ? "" : " ( " + countdown + " ) "
        }}</span>
        </div>
        <p style="width: 90%; margin: auto; line-height: 20px; font-size: 14px">
          会员到期后自动续费，可随时取消，订阅管理，可前往会员付费页面最下方进行相关操作
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { zfbPost } from "../assets/js/Common";
import axios from 'axios'
import { Toast } from 'vant';
export default {
  name: "countdown",
  data() {
    return {
      mess: this.$guize,
      countdownSeconds: 3,
      timerId: null,
      countdown: 0,
      show: false,
      showpay: true,
      timeC: "",
      timeD: "",
    };
  },
  props: {
    msg: Object,
  },
  created() {
    // console.log(this.msg);
    // this.startCountdown();
    this.timeA();
    this.timeB();
  },
  methods: {
    tongyi() {
      this.showpay = false;
      this.startCountdown();
    },
    zhifu(){
      if(this.show){
        this.clickcode()
      }
    },
    clickcode() {
            let that = this
            var appkey = this.$common.AllgetQueryId("appkey") || "woread_equity";
            let othersVal = window.location.href;
            let ip = sessionStorage.getItem('userIp')
            this.orderNo = this.$common.Allrandom_No(5)
            this.pollId = this.$common.AllgetQueryId("pollId")
            let zfbrequrl = 'https://ur.alipay.com/_3c6v5iNrQfeXV4GhfOTZvC'
            if(this.pollId == undefined){
              this.pollId =  this.msg.pollid
            }else{
            }
            let data = {
                appkey: appkey,
                bizcode: "merge_vip_order",
                orderNo: this.orderNo,
                phone: this.msg.phone,
                sign: "byt",
                ip: ip,
                imei: '',
                imsi: '',
                iccid: '',
                others: othersVal,
                pollId: this.pollId ,
                requrl: zfbrequrl,
            }
            this.$loading.show();
            axios.post('https://jifei.aizhishifm.com/rest/open/exec', data, {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                }
            })
                .then(function (res) {
                    that.$loading.hide();
                    console.log(res);
                    if (res.data.flag == true) {
                       window.location.href = res.data.data.orderUrl
                    } else {
                        Toast(res.data.msg);
                    }

                })
        },
    cloneClick() {
      this.$emit("custom-event");
      this.cancelCountdown();
    },
    startCountdown() {
      let duration = this.countdownSeconds;
      this.countdown = duration;
      this.timerId = setInterval(() => {
        duration--;
        this.countdown = duration;
        if (duration <= 0) {
          clearInterval(this.timerId);
          // zfbPost(this.msg.phone, this.msg.pollid, this.msg.pollid.whether)
          this.show = true;
        } else {
          this.show = false;
        }
      }, 1000);
    },
    cancelCountdown() {
      clearInterval(this.timerId);
      this.timerId = null;
      this.countdown = 0;
    },
    timeA() {
      let myDate = new Date();
      let year = myDate.getFullYear(); //年
      let mon =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let date =
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate();
      // return year + "-" + mon + "-" + date
      // console.log(year + "." + mon + "." + date);
      let text = year + "." + mon + "." + date;
      console.log(text);
      this.timeC = text;
    },
    timeB() {
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() + 30);
      var agoDay = `${date2.getFullYear()}.${
        date2.getMonth() + 1 < 10
          ? `0${date2.getMonth() + 1}`
          : date2.getMonth() + 1
      }.${date2.getDate()}`;
      console.log(agoDay);
      this.timeD = agoDay;
    },
  },
};
</script>

<style scoped lang="scss">
.time {
  color: whitesmoke;
  padding-left: 5px;
}
.illustrate {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  // display: none;
  z-index: 20;
}

.illustrate-content {
  position: absolute;
  left: 0;
  width: 100%;
  height: 500px;
  background-color: #fff;
  bottom: 0;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  text-align: center;
  z-index: 100;
}
.illustrate-content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto 2px;
  border-bottom: 1px solid #00000017;
  padding-bottom: 10px;
}

.illustrate-content-div {
  width: 90%;
  height: 50px;
  line-height: 50px;
  border-radius: 10px;
  background-color: rgb(35 32 32 / 10%);
  margin: 10px auto;
  font-weight: 500;
  font-size: 16px;
}

.illustrate-content-zfb {
  display: flex;
  height: 50px;
  line-height: 40px;
  justify-content: space-between;
  margin: 0 20px;
  font-weight: 600;
  font-size: 17px;
}

.illustrate-content-button {
  width: 90%;
  height: 50px;
  margin: 0px auto 20px;
  line-height: 50px;
  background: #b3afac;
  border-radius: 25px;
  color: whitesmoke;
  font-size: 20px;
  font-weight: 600;
}
</style>
