import LoadingPlugin from '../js/LoadingPlugin'
import {
    Toast
} from 'vant';
import Vue from 'vue';
import axios from 'axios'

Vue.use(LoadingPlugin)
var jifeiUrl = 'https://activity.aizhishifm.com';
var jifeiUrlB = 'https://jifei.aizhishifm.com'
var userIp = "0.0.0.0";
var a_oId = AllgetQueryId("a_oId");

//地址栏参数
function AllgetQueryId(variable) {
    // const after = window.location.hash.split('?', 2)[1]
    // if (after) {
    //     const reg = new RegExp('(^|&)' + variable + '=([^&]*)(&|$)')
    //     const r = after.match(reg)
    //     if (r != null) {
    //         return decodeURIComponent(r[2])
    //     } else {
    //         return null
    //     }
    // }
    var reg = new RegExp('(^|&)' + variable + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return decodeURI(r[2]);
    }
    return null;
}
//时间戳
function Allrandom_No(j) {
    let random_no = "";
    for (
        let i = 0; i < j; i++ //j位随机数，用以加在时间戳后面。
    ) {
        random_no += Math.floor(Math.random() * 10);
    }
    random_no = new Date().getTime() + random_no;
    return random_no;
}
//手机号
function AllisPhone(phone, item) {
    if (item == 'LT') {
        if (!/^(13[0-2]|145|15[5-6]|166|17[156]|18[56]|196)\d{8}$/.test(phone)) {
            return true;
        } else {
            return false;
        }
    } else if (item == 'YD') {
        if (!/^1(34[0-8]|705|(3[5-9]|5[0127-9]|8[23478]|78)\d)\d{7}$/.test(phone)) {
            return true
        } else {
            return false;
        }
    }
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)) {
        return true;
    } else {
        return false;
    }
}
//用户Ip
function userIpGET() {
    axios.get(jifeiUrl + "/jifei/api/getIp")
        .then(response => {
            userIp = response.data
            sessionStorage.setItem('userIp', userIp);
        })
}
let ip = sessionStorage.getItem('userIp')
if (ip) {
    userIp = ip
} else {
    userIpGET()
}
if (process.env.NODE_ENV != "production") {
    jifeiUrl = window.location.origin //开发环境
}
//接口请求
var appkey = AllgetQueryId("appkey") || "woread_equity";
let othersVal = window.location.href;
var pollId = AllgetQueryId("pollId");
var flag = true //防止重复点击
// 支付宝接口
function zfbPost(phone, poll, whether) {
    let urlpost
    let ji = 'https://activity.aizhishifm.com';
    if (flag == true) {
        //左到右为手机号，pollid，是否勾选协议
        if (AllisPhone(phone)) {
            Toast("请输入正确的手机号！");
            return;
        }
        if (whether == 0) {
            Toast("请勾选相关协议说明");
            return;
        }
        Vue.prototype.$loading.show()
        if (pollId == null || pollId == 1 || pollId == 2) {
            pollId = poll
            if (poll == 6 || poll == 15) {
                jifeiUrlB = 'https://activity.aizhishifm.com/newjifei'
            }
        } else if (pollId == 6 || pollId == 15) {
            jifeiUrlB = 'https://activity.aizhishifm.com/newjifei'
        }
        // if (pollId == null || pollId == 1 || pollId == 2) {
        //     pollId = poll
        //     urlpost = ji + '/jifei'
        // } else {
        //     urlpost = ji + '/clb/jifei'
        // }
        flag = false
        let data = {
            appkey: appkey,
            bizcode: "merge_vip_order",
            orderNo: Allrandom_No(5),
            phone: phone,
            sign: "byt",
            ip: userIp,
            imei: '',
            imsi: '',
            iccid: '',
            others: othersVal,
            pollId: pollId,
            requrl: "https://ur.alipay.com/_3c6v5iNrQfeXV4GhfOTZvC",
        }
        axios.post(jifeiUrlB + "/rest/open/exec", data, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
            .then((result) => {
                if (result.data.flag == true) {
                    Vue.prototype.$loading.hide()
                    if (a_oId) {
                        // 推啊
                        countLog.ready(function () {
                            countLog.init(function () { }, {
                                // 是否需要复制订单号，是为 1，不是为 0
                                isCopy: 0,
                                // 上报转化类型，具体见下表
                                pageType: 0,
                            });
                        });
                    }
                    window.location.href = result.data.data.orderUrl
                } else {
                    Vue.prototype.$loading.hide()
                    setTimeout(() => {
                        liuliangguo(phone, 100875, "")
                    }, 1000)
                    Toast(result.data.msg)
                }
                flag = true
            })
            .catch((error) => {
                Vue.prototype.$loading.hide()
                flag = true
                Toast("服务器繁忙")
            })
    }

}
//微信接口
function AllsanwangPost(phoneNum, poll, whether) {
    if (AllisPhone(phoneNum)) {
        Toast("请输入正确的手机号！");
        return;
    }
    if (whether == 0) {
        Toast("请勾选相关协议说明");
        return;
    }
    if (pollId == null || pollId == 1 || pollId == 2) {
        pollId = poll
    }
    jifeiUrl = 'https://activity.aizhishifm.com/jifei'
    let data = {
        appkey: appkey,
        bizcode: "merge_vip_order",
        orderNo: Allrandom_No(5),
        phone: phoneNum,
        sign: "byt",
        ip: userIp,
        imei: '',
        imsi: '',
        iccid: '',
        others: othersVal,
        pollId: pollId,
        requrl: 'https://u.mbldbg.com/2b90',
    }
    axios.post(jifeiUrl + "/rest/open/exec", data, {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    })
        .then((result) => {
            if (result.data.flag == true) {
                setTimeout(() => {
                    window.location.href = result.data.data.orderUrl
                }, 1000)
                if (a_oId) {
                    // 推啊
                    countLog.ready(function () {
                        countLog.init(function () { }, {
                            // 是否需要复制订单号，是为 1，不是为 0
                            isCopy: 0,
                            // 上报转化类型，具体见下表
                            pageType: 0,
                        });
                    });
                }
            } else {
                Toast(result.data.msg)
            }
            flag = true
        })
        .catch((error) => {
            flag = true
            Toast("服务器繁忙")
        })
}
// 微信支付宝通用接口
function WxPost(payment, product, phoneNum, whether) {
    let urlpost
    let ji = 'https://activity.aizhishifm.com';
    if (flag == true) {
        if (payment == "alipay_request") {
            urlpost = ji + '/clb/jifei'
        } else {
            urlpost = ji + '/jifei'
        }
        if (AllisPhone(phoneNum)) {
            Toast("请输入正确的手机号！");
            return;
        }
        if (whether == 0) {
            Toast("请勾选相关协议说明");
            return;
        }
        Vue.prototype.$loading.show()
        flag = false
        let data = {
            appkey: appkey,
            bizcode: payment,
            orderNo: Allrandom_No(5),
            phone: phoneNum,
            sign: "byt",
            ip: userIp,
            others: othersVal,
            requrl: "https://m.woread.diamond.aizhishifm.cn/benefit.html",
            channelid: "",
            productId: product,
        }
        axios.post(urlpost + "/rest/open/exec", data, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })

            .then((result) => {
                if (result.data.flag == true) {
                    setTimeout(() => {
                        Vue.prototype.$loading.hide()
                        window.location.href = result.data.data.orderUrl
                    }, 1000)
                    if (a_oId) {
                        // 推啊
                        countLog.ready(function () {
                            countLog.init(function () { }, {
                                // 是否需要复制订单号，是为 1，不是为 0
                                isCopy: 0,
                                // 上报转化类型，具体见下表
                                pageType: 0,
                            });
                        });
                    }
                } else {
                    Vue.prototype.$loading.hide()
                    Toast(result.data.msg)
                }
                flag = true
            })
            .catch((error) => {
                Vue.prototype.$loading.hide()
                flag = true
                Toast("服务器繁忙")
            })
    }
}
//禁止滚动
function banroll() {
    let m = function (e) {
        e.preventDefault();
    };
    document.body.style.overflow = 'hidden';
    document.addEventListener("touchmove", m, {
        passive: false
    }); //禁止页面滑动
}
//允许滚动
function abbrroll() {
    let m = function (e) {
        e.preventDefault();
    };
    document.body.style.overflow = ''; //出现滚动条
    document.removeEventListener("touchmove", m, {
        passive: true
    });
}
//广告位跳转
function liuliangguo(phone, id, fail) {
    var llgSDK = typeof llgsdk === 'undefined' ? '' : llgsdk;
    //获取广告接口入参
    var params = {
        app_key: 'LLG10005518 ',
        app_secret: 'bc6d9be92bb830c0a814865f58de1464',
        ad_position_id: id,
        phone: phone,
        fail_message: fail,
        product_name: '联合会员'
    };

    //获取广告并展示
    llgSDK && llgSDK.getAdAndShow(params, function (res) {
        if (!res.is_success) {
            console.log(res.message)
        }
    })
}
export {
    AllgetQueryId,
    Allrandom_No,
    zfbPost,
    WxPost,
    banroll,
    abbrroll,
    AllisPhone,
    AllsanwangPost,
    liuliangguo,
}