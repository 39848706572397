<template>
    <van-popup v-model="show" style="width: 90%;height: 80%;">
      <div class="wrapper" @click.stop>
        <h4>{{ msgB.ruletext }}</h4>
        <div v-html="msgB.messB"></div>
      </div>
    </van-popup>
</template>
  
<script>
export default {
  name: 'rule',
  data() {
    return {
      show: false,
    }
  },
  props: {
    msgB: Object
  },
  destroyed() {
      this.tuichu()
    },
  methods: {
    tuichu(){
      this.show = true
    },
    close() {
      this.$emit('custom-eventB')
    },
  }
}
</script>
  
<style scoped lang="scss">
.time {
  color: #3b3b3b98;
  padding-left: 5px;
}

.wrapper {
  // width: 90%;
  // height: 80%;
  margin: auto;
  z-index: 30;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #FFFFFF;
  border-radius: 5px;
  border-bottom: 1px solid #e7e4dc;

  div {
    font-size: 16px;
    text-align: left;
    padding: 10px;
    color: #c36018;
    overflow: auto;
    height: 81%;
  }

  h4 {
    padding: 10px;
    margin: 1px;
    color: #c36018;
    border-bottom: 1px solid #e7e4dc;
  }
}</style>
  