import 'lib-flexible/flexible.js'
import 'vant/lib/index.css';
import './assets/css/Allcss.css'

import  * as Common from './assets/js/Common'

import App from './App.vue'
import LoadingPlugin  from './assets/js/LoadingPlugin';
import Vant from 'vant';
import Vue from 'vue'
import axios from 'axios';
import countdown from './components/countdown.vue'; //全局倒计时弹窗组件
import Wxcountdown from './components/Wxcountdown.vue'; //全局倒计时弹窗组件
import guize from './assets/js/agreement';
import router from './router'
import rule from './components/rule.vue'; //全局规则和隐私组件
import { Base64 } from 'js-base64';

//全局协议数据
Vue.component('my-countdown', countdown);
Vue.component('my-Wxcountdown', Wxcountdown);
Vue.component('my-rule', rule);

Vue.prototype.$common = Common;
// Vue.prototype.$loading = false;
Vue.config.productionTip = false
Vue.prototype.$Base64 = Base64;


Vue.prototype.$guize = guize
Vue.use(Vant);
Vue.use(LoadingPlugin);
new Vue({
  axios,
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')