<template>
    <div class="load" v-if="visible">
        <div class="loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false, // 控制 loading 显示/隐藏
      };
    },
  };
  </script>
