import LoadingComponent from '../../components/Loading.vue';

const LoadingPlugin = {};

LoadingPlugin.install = function (Vue) {
  // 创建一个 Vue 的子类，用于创建 loading 实例
  const LoadingConstructor = Vue.extend(LoadingComponent);

  // 创建 Loading 实例并挂载到文档之外的地方
  const instance = new LoadingConstructor().$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);

  // 在 Vue 原型上添加实例方法，以全局调用 Loading
  Vue.prototype.$loading = {
    show: function () {
      instance.visible = true;
    },
    hide: function () {
      instance.visible = false;
    },
  };
};

export default LoadingPlugin;